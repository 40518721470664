@import "/src/layouts/comMaster/responsive.scss";

.header {
    $root: &;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--main);
    color: var(--light);
    * {
        color: var(--light);
    }
    &__heading {
        height: 50px;
        width: calc(100% - 50px - 50px);
        flex: 0 0 calc(100% - 50px - 50px);
        display: flex;
        justify-content: center;
        align-items: center;
        h3 {
            width: 100%;
            flex: 0 0 100%;
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            font-size: 15px;
            font-weight: 500;
            text-transform: uppercase;
            text-align: center;
        }
    }
    &__left {
        height: 50px;
        width: 50px;
        flex: 0 0 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            font-size: 18px;
            color: var(--light);
            position: relative;
            span {
                position: absolute;
                top: -9px;
                left: 70%;
                height: 18px;
                line-height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 3px 0 3px;
                border-radius: 100%;
                font-size: 11px;
                font-weight: 600;
                color: var(--block);
                white-space: nowrap;
                min-width: 17px;
            }
        }
        &:hover {
            i {
                color: var(--hover);
                span {
                    color: var(--hover);
                }
            }
        }
    }
    &__middle {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: calc(100% - 50px - 50px);
        flex: 0 0 calc(100% - 50px - 50px);
        @include xxs() {
            display: none;
        }
        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            @include sm() {
                max-width: 140px;
            }
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                @include xxs() {
                    padding-left: 0;
                }
            }
        }
    }
    &__right {
        height: 50px;
        width: 50px;
        flex: 0 0 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            font-size: 20px;
            color: var(--light);
        }
        > img {
            width: 38px;
            height: 38px;
            border-radius: 100%;
            object-fit: cover;
            border: 2px solid var(--hover);
            margin-right: 15px;
            @include xs() {
                margin-right: 13px;
            }
        }
        &:hover {
            i {
                color: var(--hover);
            }
        }
        #{$root}__right-info {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
            margin: 0 5px;
            strong {
                font-size: 13px;
                font-weight: 400;
                color: var(--light);
                line-height: 15px;
                display: inline-block;
                max-width: 90px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                max-width: 145px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                i {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 14px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-style: normal;
                    @include xxs() {
                        display: none;
                    }
                }
            }
        }
    }
    &__logged {
        justify-content: initial;
        #{$root}__middle {
            width: fit-content;
            flex: 0 0 fit-content;
        }
        #{$root}__right {
            width: fit-content;
            flex: 0 0 fit-content;
            min-width: 50px;
            margin-left: auto;
        }
    }
}
