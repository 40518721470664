@import "/src/layouts/comMaster/responsive.scss";

.result {
    $root: &;
    padding: 10px;
    #{$root}__item {
        margin: 10px 0;
        padding: 10px 0 10px 10px;
        background: var(--light);
        box-shadow: 0px 0px 5px var(--block);
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        #{$root}__item-info {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
                width: 50px;
                height: 50px;
                margin-right: 10px;
            }
            h3 {
                color: var(--default);
                font-weight: 500;
                font-size: 14px;
                strong {
                    color: var(--default);
                    font-weight: 500;
                    font-size: 14px;
                    margin-left: 5px;
                    display: block;
                }
                > span {
                    display: block;
                    font-size: 13px;
                    margin-left: 5px;
                    color: var(--main);
                }
            }
        }
        > i {
            flex: 0 0 35px;
            width: 35px;
            height: 35px;
            display: flex;
            margin-left: 10px;
            justify-content: center;
            align-items: center;
            color: var(--block);
            font-size: 25px;
            cursor: pointer;
        }
    }
}

.result-game {
    $root: &;
    padding: 10px;
    #{$root}__item {
        margin: 10px 0;
        padding: 10px 5px 10px 10px;
        background: var(--light);
        box-shadow: 0px 0px 5px var(--block);
        border-radius: 10px;
        #{$root}__item-row {
            flex: 0 0 100%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                color: var(--default);
                font-size: 12px;
                &:last-child {
                    padding-right: 5px;
                }
            }
            &:first-child {
                span {
                    font-size: 13px;
                    font-weight: 500;
                    color: var(--main);
                }
            }
        }
    }
}
