@import "/src/layouts/comMaster/responsive.scss";
button,
[type="button"],
[type="submit"] {
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: baseline;
    padding: 4px;
    border-radius: 50px;
    i {
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
}
.btn {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: baseline;
    padding: 8px 16px;
    border-radius: 50px;
    font-size: 14px;
    line-height: 1.5;
    transition: var(--transition);
    box-shadow: 0px 0px 3px transparent;
    background: var(--main-hover);
    color: var(--light);
    &:hover {
        box-shadow: 0px 0px 1px var(--main-hover);
    }
    i {
        font-size: 12px;
        line-height: 1.5;
        margin: 0 5px;
        color: var(--light);
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    *:first-child {
        margin-left: 0;
    }
    *:last-child:not(:first-child) {
        margin-right: 0;
    }
    &.btn__small {
        padding: 4px 8px;
        font-size: 11px;
        i {
            font-size: 11px;
        }
    }
    &.btn__medium {
        padding: 6px 14px;
        font-size: 13px;
        i {
            font-size: 13px;
        }
    }
    &.btn__big {
        padding: 12px 16px;
        font-size: 16px;
        i {
            font-size: 16px;
        }
    }
    &.btn__primary {
        background: var(--primary);
        color: var(--light);
        &:hover {
            box-shadow: 0px 0px 1px var(--primary);
        }
    }
    &.btn__warning {
        background: var(--warning);
        color: var(--light);
        &:hover {
            box-shadow: 0px 0px 1px var(--warning);
        }
    }
    &.btn__success {
        background: var(--success);
        color: var(--light);
        &:hover {
            box-shadow: 0px 0px 1px var(--success);
        }
    }
    &.btn__info {
        background: var(--info);
        color: var(--light);
        &:hover {
            box-shadow: 0px 0px 1px var(--info);
        }
    }
    &.btn__default {
        background: var(--default);
        color: var(--light);
        &:hover {
            box-shadow: 0px 0px 1px var(--default);
        }
    }
    &.btn__danger {
        background: var(--danger);
        color: var(--light);
        &:hover {
            box-shadow: 0px 0px 1px var(--danger);
        }
    }
    &.btn__dark {
        background: var(--dark);
        color: var(--light);
        &:hover {
            box-shadow: 0px 0px 1px var(--dark);
        }
    }
    &:disabled {
        filter: grayscale(0.1) brightness(0.8);
        &:hover {
            box-shadow: none;
        }
    }
}
