@import "/src/layouts/comMaster/responsive.scss";
.container {
    margin: 0 auto;
    width: 1200px;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    @include lg() {
        padding: 0 6px;
    }
    @include xs() {
        padding: 0px;
    }
    > .row {
        width: 100%;
        max-width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
        flex-wrap: wrap;
        > .col-12,
        > .col-11,
        > .col-10,
        > .col-9,
        > .col-8,
        > .col-7,
        > .col-6,
        > .col-5,
        > .col-4,
        > .col-3,
        > .col-2,
        > .col-1,
        > .col-xxl-12,
        > .col-xxl-11,
        > .col-xxl-10,
        > .col-xxl-9,
        > .col-xxl-8,
        > .col-xxl-7,
        > .col-xxl-6,
        > .col-xxl-5,
        > .col-xxl-4,
        > .col-xxl-3,
        > .col-xxl-2,
        > .col-xxl-1,
        > .col-xl-12,
        > .col-xl-11,
        > .col-xl-10,
        > .col-xl-9,
        > .col-xl-8,
        > .col-xl-7,
        > .col-xl-6,
        > .col-xl-5,
        > .col-xl-4,
        > .col-xl-3,
        > .col-xl-2,
        > .col-xl-1,
        > .col-lg-12,
        > .col-lg-11,
        > .col-lg-10,
        > .col-lg-9,
        > .col-lg-8,
        > .col-lg-7,
        > .col-lg-6,
        > .col-lg-5,
        > .col-lg-4,
        > .col-lg-3,
        > .col-lg-2,
        > .col-lg-1,
        > .col-md-12,
        > .col-md-11,
        > .col-md-10,
        > .col-md-9,
        > .col-md-8,
        > .col-md-7,
        > .col-md-6,
        > .col-md-5,
        > .col-md-4,
        > .col-md-3,
        > .col-md-2,
        > .col-md-1,
        > .col-sm-12,
        > .col-sm-11,
        > .col-sm-10,
        > .col-sm-9,
        > .col-sm-8,
        > .col-sm-7,
        > .col-sm-6,
        > .col-sm-5,
        > .col-sm-4,
        > .col-sm-3,
        > .col-sm-2,
        > .col-sm-1,
        > .col-xs-12,
        > .col-xs-11,
        > .col-xs-10,
        > .col-xs-9,
        > .col-xs-8,
        > .col-xs-7,
        > .col-xs-6,
        > .col-xs-5,
        > .col-xs-4,
        > .col-xs-3,
        > .col-xs-2,
        > .col-xs-1,
        > .col-xxs-12,
        > .col-xxs-11,
        > .col-xxs-10,
        > .col-xxs-9,
        > .col-xxs-8,
        > .col-xxs-7,
        > .col-xxs-6,
        > .col-xxs-5,
        > .col-xxs-4,
        > .col-xxs-3,
        > .col-xxs-2,
        > .col-xxs-1 {
            display: block;
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0 10px;
            @include lg() {
                padding: 0 6px;
            }
        }
        > .col-12 {
            width: 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        > .col-11 {
            width: calc(100% * 11 / 12);
            flex: 0 0 calc(100% * 11 / 12);
            max-width: calc(100% * 11 / 12);
        }
        > .col-10 {
            width: calc(100% * 10 / 12);
            flex: 0 0 calc(100% * 10 / 12);
            max-width: calc(100% * 10 / 12);
        }
        > .col-9 {
            width: calc(100% * 9 / 12);
            flex: 0 0 calc(100% * 9 / 12);
            max-width: calc(100% * 9 / 12);
        }
        > .col-8 {
            width: calc(100% * 8 / 12);
            flex: 0 0 calc(100% * 8 / 12);
            max-width: calc(100% * 8 / 12);
        }
        > .col-7 {
            width: calc(100% * 7 / 12);
            flex: 0 0 calc(100% * 7 / 12);
            max-width: calc(100% * 7 / 12);
        }
        > .col-6 {
            width: calc(100% * 6 / 12);
            flex: 0 0 calc(100% * 6 / 12);
            max-width: calc(100% * 6 / 12);
        }
        > .col-5 {
            width: calc(100% * 5 / 12);
            flex: 0 0 calc(100% * 5 / 12);
            max-width: calc(100% * 5 / 12);
        }
        > .col-4 {
            width: calc(100% * 4 / 12);
            flex: 0 0 calc(100% * 4 / 12);
            max-width: calc(100% * 4 / 12);
        }
        > .col-3 {
            width: calc(100% * 3 / 12);
            flex: 0 0 calc(100% * 3 / 12);
            max-width: calc(100% * 3 / 12);
        }
        > .col-2 {
            width: calc(100% * 2 / 12);
            flex: 0 0 calc(100% * 2 / 12);
            max-width: calc(100% * 2 / 12);
        }
        > .col-1 {
            width: calc(100% * 1 / 12);
            flex: 0 0 calc(100% * 1 / 12);
            max-width: calc(100% * 1 / 12);
        }
        > .col-xxl-12 {
            @include xxl() {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        > .col-xxl-11 {
            @include xxl() {
                width: calc(100% * 11 / 12);
                flex: 0 0 calc(100% * 11 / 12);
                max-width: calc(100% * 11 / 12);
            }
        }
        > .col-xxl-10 {
            @include xxl() {
                width: calc(100% * 10 / 12);
                flex: 0 0 calc(100% * 10 / 12);
                max-width: calc(100% * 10 / 12);
            }
        }
        > .col-xxl-9 {
            @include xxl() {
                width: calc(100% * 9 / 12);
                flex: 0 0 calc(100% * 9 / 12);
                max-width: calc(100% * 9 / 12);
            }
        }
        > .col-xxl-8 {
            @include xxl() {
                width: calc(100% * 8 / 12);
                flex: 0 0 calc(100% * 8 / 12);
                max-width: calc(100% * 8 / 12);
            }
        }
        > .col-xxl-7 {
            @include xxl() {
                width: calc(100% * 7 / 12);
                flex: 0 0 calc(100% * 7 / 12);
                max-width: calc(100% * 7 / 12);
            }
        }
        > .col-xxl-6 {
            @include xxl() {
                width: calc(100% * 6 / 12);
                flex: 0 0 calc(100% * 6 / 12);
                max-width: calc(100% * 6 / 12);
            }
        }
        > .col-xxl-5 {
            @include xxl() {
                width: calc(100% * 5 / 12);
                flex: 0 0 calc(100% * 5 / 12);
                max-width: calc(100% * 5 / 12);
            }
        }
        > .col-xxl-4 {
            @include xxl() {
                width: calc(100% * 4 / 12);
                flex: 0 0 calc(100% * 4 / 12);
                max-width: calc(100% * 4 / 12);
            }
        }
        > .col-xxl-3 {
            @include xxl() {
                width: calc(100% * 3 / 12);
                flex: 0 0 calc(100% * 3 / 12);
                max-width: calc(100% * 3 / 12);
            }
        }
        > .col-xxl-2 {
            @include xxl() {
                width: calc(100% * 2 / 12);
                flex: 0 0 calc(100% * 2 / 12);
                max-width: calc(100% * 2 / 12);
            }
        }
        > .col-xxl-1 {
            @include xxl() {
                width: calc(100% * 1 / 12);
                flex: 0 0 calc(100% * 1 / 12);
                max-width: calc(100% * 1 / 12);
            }
        }
        > .col-xl-12 {
            @include xl() {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        > .col-xl-11 {
            @include xl() {
                width: calc(100% * 11 / 12);
                flex: 0 0 calc(100% * 11 / 12);
                max-width: calc(100% * 11 / 12);
            }
        }
        > .col-xl-10 {
            @include xl() {
                width: calc(100% * 10 / 12);
                flex: 0 0 calc(100% * 10 / 12);
                max-width: calc(100% * 10 / 12);
            }
        }
        > .col-xl-9 {
            @include xl() {
                width: calc(100% * 9 / 12);
                flex: 0 0 calc(100% * 9 / 12);
                max-width: calc(100% * 9 / 12);
            }
        }
        > .col-xl-8 {
            @include xl() {
                width: calc(100% * 8 / 12);
                flex: 0 0 calc(100% * 8 / 12);
                max-width: calc(100% * 8 / 12);
            }
        }
        > .col-xl-7 {
            @include xl() {
                width: calc(100% * 7 / 12);
                flex: 0 0 calc(100% * 7 / 12);
                max-width: calc(100% * 7 / 12);
            }
        }
        > .col-xl-6 {
            @include xl() {
                width: calc(100% * 6 / 12);
                flex: 0 0 calc(100% * 6 / 12);
                max-width: calc(100% * 6 / 12);
            }
        }
        > .col-xl-5 {
            @include xl() {
                width: calc(100% * 5 / 12);
                flex: 0 0 calc(100% * 5 / 12);
                max-width: calc(100% * 5 / 12);
            }
        }
        > .col-xl-4 {
            @include xl() {
                width: calc(100% * 4 / 12);
                flex: 0 0 calc(100% * 4 / 12);
                max-width: calc(100% * 4 / 12);
            }
        }
        > .col-xl-3 {
            @include xl() {
                width: calc(100% * 3 / 12);
                flex: 0 0 calc(100% * 3 / 12);
                max-width: calc(100% * 3 / 12);
            }
        }
        > .col-xl-2 {
            @include xl() {
                width: calc(100% * 2 / 12);
                flex: 0 0 calc(100% * 2 / 12);
                max-width: calc(100% * 2 / 12);
            }
        }
        > .col-xl-1 {
            @include xl() {
                width: calc(100% * 1 / 12);
                flex: 0 0 calc(100% * 1 / 12);
                max-width: calc(100% * 1 / 12);
            }
        }
        > .col-lg-12 {
            @include lg() {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        > .col-lg-11 {
            @include lg() {
                width: calc(100% * 11 / 12);
                flex: 0 0 calc(100% * 11 / 12);
                max-width: calc(100% * 11 / 12);
            }
        }
        > .col-lg-10 {
            @include lg() {
                width: calc(100% * 10 / 12);
                flex: 0 0 calc(100% * 10 / 12);
                max-width: calc(100% * 10 / 12);
            }
        }
        > .col-lg-9 {
            @include lg() {
                width: calc(100% * 9 / 12);
                flex: 0 0 calc(100% * 9 / 12);
                max-width: calc(100% * 9 / 12);
            }
        }
        > .col-lg-8 {
            @include lg() {
                width: calc(100% * 8 / 12);
                flex: 0 0 calc(100% * 8 / 12);
                max-width: calc(100% * 8 / 12);
            }
        }
        > .col-lg-7 {
            @include lg() {
                width: calc(100% * 7 / 12);
                flex: 0 0 calc(100% * 7 / 12);
                max-width: calc(100% * 7 / 12);
            }
        }
        > .col-lg-6 {
            @include lg() {
                width: calc(100% * 6 / 12);
                flex: 0 0 calc(100% * 6 / 12);
                max-width: calc(100% * 6 / 12);
            }
        }
        > .col-lg-5 {
            @include lg() {
                width: calc(100% * 5 / 12);
                flex: 0 0 calc(100% * 5 / 12);
                max-width: calc(100% * 5 / 12);
            }
        }
        > .col-lg-4 {
            @include lg() {
                width: calc(100% * 4 / 12);
                flex: 0 0 calc(100% * 4 / 12);
                max-width: calc(100% * 4 / 12);
            }
        }
        > .col-lg-3 {
            @include lg() {
                width: calc(100% * 3 / 12);
                flex: 0 0 calc(100% * 3 / 12);
                max-width: calc(100% * 3 / 12);
            }
        }
        > .col-lg-2 {
            @include lg() {
                width: calc(100% * 2 / 12);
                flex: 0 0 calc(100% * 2 / 12);
                max-width: calc(100% * 2 / 12);
            }
        }
        > .col-lg-1 {
            @include lg() {
                width: calc(100% * 1 / 12);
                flex: 0 0 calc(100% * 1 / 12);
                max-width: calc(100% * 1 / 12);
            }
        }
        > .col-md-12 {
            @include md() {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        > .col-md-11 {
            @include md() {
                width: calc(100% * 11 / 12);
                flex: 0 0 calc(100% * 11 / 12);
                max-width: calc(100% * 11 / 12);
            }
        }
        > .col-md-10 {
            @include md() {
                width: calc(100% * 10 / 12);
                flex: 0 0 calc(100% * 10 / 12);
                max-width: calc(100% * 10 / 12);
            }
        }
        > .col-md-9 {
            @include md() {
                width: calc(100% * 9 / 12);
                flex: 0 0 calc(100% * 9 / 12);
                max-width: calc(100% * 9 / 12);
            }
        }
        > .col-md-8 {
            @include md() {
                width: calc(100% * 8 / 12);
                flex: 0 0 calc(100% * 8 / 12);
                max-width: calc(100% * 8 / 12);
            }
        }
        > .col-md-7 {
            @include md() {
                width: calc(100% * 7 / 12);
                flex: 0 0 calc(100% * 7 / 12);
                max-width: calc(100% * 7 / 12);
            }
        }
        > .col-md-6 {
            @include md() {
                width: calc(100% * 6 / 12);
                flex: 0 0 calc(100% * 6 / 12);
                max-width: calc(100% * 6 / 12);
            }
        }
        > .col-md-5 {
            @include md() {
                width: calc(100% * 5 / 12);
                flex: 0 0 calc(100% * 5 / 12);
                max-width: calc(100% * 5 / 12);
            }
        }
        > .col-md-4 {
            @include md() {
                width: calc(100% * 4 / 12);
                flex: 0 0 calc(100% * 4 / 12);
                max-width: calc(100% * 4 / 12);
            }
        }
        > .col-md-3 {
            @include md() {
                width: calc(100% * 3 / 12);
                flex: 0 0 calc(100% * 3 / 12);
                max-width: calc(100% * 3 / 12);
            }
        }
        > .col-md-2 {
            @include md() {
                width: calc(100% * 2 / 12);
                flex: 0 0 calc(100% * 2 / 12);
                max-width: calc(100% * 2 / 12);
            }
        }
        > .col-md-1 {
            @include md() {
                width: calc(100% * 1 / 12);
                flex: 0 0 calc(100% * 1 / 12);
                max-width: calc(100% * 1 / 12);
            }
        }
        > .col-sm-12 {
            @include sm() {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        > .col-sm-11 {
            @include sm() {
                width: calc(100% * 11 / 12);
                flex: 0 0 calc(100% * 11 / 12);
                max-width: calc(100% * 11 / 12);
            }
        }
        > .col-sm-10 {
            @include sm() {
                width: calc(100% * 10 / 12);
                flex: 0 0 calc(100% * 10 / 12);
                max-width: calc(100% * 10 / 12);
            }
        }
        > .col-sm-9 {
            @include sm() {
                width: calc(100% * 9 / 12);
                flex: 0 0 calc(100% * 9 / 12);
                max-width: calc(100% * 9 / 12);
            }
        }
        > .col-sm-8 {
            @include sm() {
                width: calc(100% * 8 / 12);
                flex: 0 0 calc(100% * 8 / 12);
                max-width: calc(100% * 8 / 12);
            }
        }
        > .col-sm-7 {
            @include sm() {
                width: calc(100% * 7 / 12);
                flex: 0 0 calc(100% * 7 / 12);
                max-width: calc(100% * 7 / 12);
            }
        }
        > .col-sm-6 {
            @include sm() {
                width: calc(100% * 6 / 12);
                flex: 0 0 calc(100% * 6 / 12);
                max-width: calc(100% * 6 / 12);
            }
        }
        > .col-sm-5 {
            @include sm() {
                width: calc(100% * 5 / 12);
                flex: 0 0 calc(100% * 5 / 12);
                max-width: calc(100% * 5 / 12);
            }
        }
        > .col-sm-4 {
            @include sm() {
                width: calc(100% * 4 / 12);
                flex: 0 0 calc(100% * 4 / 12);
                max-width: calc(100% * 4 / 12);
            }
        }
        > .col-sm-3 {
            @include sm() {
                width: calc(100% * 3 / 12);
                flex: 0 0 calc(100% * 3 / 12);
                max-width: calc(100% * 3 / 12);
            }
        }
        > .col-sm-2 {
            @include sm() {
                width: calc(100% * 2 / 12);
                flex: 0 0 calc(100% * 2 / 12);
                max-width: calc(100% * 2 / 12);
            }
        }
        > .col-sm-1 {
            @include sm() {
                width: calc(100% * 1 / 12);
                flex: 0 0 calc(100% * 1 / 12);
                max-width: calc(100% * 1 / 12);
            }
        }
        > .col-xs-12 {
            @include xs() {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        > .col-xs-11 {
            @include xs() {
                width: calc(100% * 11 / 12);
                flex: 0 0 calc(100% * 11 / 12);
                max-width: calc(100% * 11 / 12);
            }
        }
        > .col-xs-10 {
            @include xs() {
                width: calc(100% * 10 / 12);
                flex: 0 0 calc(100% * 10 / 12);
                max-width: calc(100% * 10 / 12);
            }
        }
        > .col-xs-9 {
            @include xs() {
                width: calc(100% * 9 / 12);
                flex: 0 0 calc(100% * 9 / 12);
                max-width: calc(100% * 9 / 12);
            }
        }
        > .col-xs-8 {
            @include xs() {
                width: calc(100% * 8 / 12);
                flex: 0 0 calc(100% * 8 / 12);
                max-width: calc(100% * 8 / 12);
            }
        }
        > .col-xs-7 {
            @include xs() {
                width: calc(100% * 7 / 12);
                flex: 0 0 calc(100% * 7 / 12);
                max-width: calc(100% * 7 / 12);
            }
        }
        > .col-xs-6 {
            @include xs() {
                width: calc(100% * 6 / 12);
                flex: 0 0 calc(100% * 6 / 12);
                max-width: calc(100% * 6 / 12);
            }
        }
        > .col-xs-5 {
            @include xs() {
                width: calc(100% * 5 / 12);
                flex: 0 0 calc(100% * 5 / 12);
                max-width: calc(100% * 5 / 12);
            }
        }
        > .col-xs-4 {
            @include xs() {
                width: calc(100% * 4 / 12);
                flex: 0 0 calc(100% * 4 / 12);
                max-width: calc(100% * 4 / 12);
            }
        }
        > .col-xs-3 {
            @include xs() {
                width: calc(100% * 3 / 12);
                flex: 0 0 calc(100% * 3 / 12);
                max-width: calc(100% * 3 / 12);
            }
        }
        > .col-xs-2 {
            @include xs() {
                width: calc(100% * 2 / 12);
                flex: 0 0 calc(100% * 2 / 12);
                max-width: calc(100% * 2 / 12);
            }
        }
        > .col-xs-1 {
            @include xs() {
                width: calc(100% * 1 / 12);
                flex: 0 0 calc(100% * 1 / 12);
                max-width: calc(100% * 1 / 12);
            }
        }
        > .col-xxs-12 {
            @include xxs() {
                width: 100%;
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
        > .col-xxs-11 {
            @include xxs() {
                width: calc(100% * 11 / 12);
                flex: 0 0 calc(100% * 11 / 12);
                max-width: calc(100% * 11 / 12);
            }
        }
        > .col-xxs-10 {
            @include xxs() {
                width: calc(100% * 10 / 12);
                flex: 0 0 calc(100% * 10 / 12);
                max-width: calc(100% * 10 / 12);
            }
        }
        > .col-xxs-9 {
            @include xxs() {
                width: calc(100% * 9 / 12);
                flex: 0 0 calc(100% * 9 / 12);
                max-width: calc(100% * 9 / 12);
            }
        }
        > .col-xxs-8 {
            @include xxs() {
                width: calc(100% * 8 / 12);
                flex: 0 0 calc(100% * 8 / 12);
                max-width: calc(100% * 8 / 12);
            }
        }
        > .col-xxs-7 {
            @include xxs() {
                width: calc(100% * 7 / 12);
                flex: 0 0 calc(100% * 7 / 12);
                max-width: calc(100% * 7 / 12);
            }
        }
        > .col-xxs-6 {
            @include xxs() {
                width: calc(100% * 6 / 12);
                flex: 0 0 calc(100% * 6 / 12);
                max-width: calc(100% * 6 / 12);
            }
        }
        > .col-xxs-5 {
            @include xxs() {
                width: calc(100% * 5 / 12);
                flex: 0 0 calc(100% * 5 / 12);
                max-width: calc(100% * 5 / 12);
            }
        }
        > .col-xxs-4 {
            @include xxs() {
                width: calc(100% * 4 / 12);
                flex: 0 0 calc(100% * 4 / 12);
                max-width: calc(100% * 4 / 12);
            }
        }
        > .col-xxs-3 {
            @include xxs() {
                width: calc(100% * 3 / 12);
                flex: 0 0 calc(100% * 3 / 12);
                max-width: calc(100% * 3 / 12);
            }
        }
        > .col-xxs-2 {
            @include xxs() {
                width: calc(100% * 2 / 12);
                flex: 0 0 calc(100% * 2 / 12);
                max-width: calc(100% * 2 / 12);
            }
        }
        > .col-xxs-1 {
            @include xxs() {
                width: calc(100% * 1 / 12);
                flex: 0 0 calc(100% * 1 / 12);
                max-width: calc(100% * 1 / 12);
            }
        }
    }
}
