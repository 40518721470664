@import "/src/layouts/comMaster/responsive.scss";
.slide-container {
    height: 0;
    padding-bottom: 35%;
    position: relative;
    cursor: pointer;
    .slide-image {
        $root: &;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;
    }
}
