@import "/src/layouts/comMaster/responsive.scss";

div.validator__error,
span.validator__error,
label.validator__error,
p.validator__error,
i.validator__error {
    color: red;
    font-size: 12px;
    width: 100%;
    flex: 0 0 100%;
    display: block;
    margin-top: 3px;
    font-style: italic;
}
