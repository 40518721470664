@import "/src/layouts/comMaster/responsive.scss";
.input {
    width: 100%;
    flex: 0 0 100%;
    position: relative;
    input {
        border-radius: 10px;
        &.validator__error {
            border-color: red !important;
        }
    }
    > span {
        position: absolute;
        bottom: 100%;
        right: 2px;
        font-size: 12px;
        color: #8b97a3;
    }
}
