@import "/src/layouts/comMaster/responsive.scss";

.sending {
    $root: &;

    display: inline-flex;
    width: fit-content;
    height: fit-content;
    a {
        display: inline-flex;
        width: fit-content;
        height: fit-content;

        img {
            height: 20px;
        }
    }
}
