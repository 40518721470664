@import "/src/layouts/comMaster/responsive.scss";

.label {
    line-height: 22px;
    display: inline-block;
    width: fit-content;
    height: 22px;
    padding: 0 5px;
    border-radius: 3px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px !important;
    color: var(--main);
    background-color: var(--line);
    cursor: pointer;
    margin: 2px;

    &__main {
        color: var(--light);
        background-color: var(--main);
    }
    &__text {
        color: var(--light);
        background-color: var(--text);
    }
    &__light {
        background-color: var(--light);
    }
    &__link {
        color: var(--light);
        background-color: var(--link);
    }
    &__link-hover {
        color: var(--light);
        background-color: var(--link-hover);
    }
    &__hover {
        color: var(--light);
        background-color: var(--hover);
    }
    &__block {
        color: var(--main);
        background-color: var(--block);
    }
    &__line {
        color: var(--main);
        background-color: var(--line);
    }
    &__note {
        color: var(--main);
        background-color: var(--note);
    }
    &__primary {
        color: var(--light);
        background-color: var(--primary);
    }
    &__warning {
        color: var(--light);
        background-color: var(--warning);
    }
    &__success {
        color: var(--light);
        background-color: var(--success);
    }
    &__info {
        color: var(--light);
        background-color: var(--info);
    }
    &__default {
        color: var(--light);
        background-color: var(--default);
    }
    &__danger {
        color: var(--light);
        background-color: var(--danger);
    }
    &__dark {
        color: var(--light);
        background-color: var(--dark);
    }
}
