@import "/src/layouts/comMaster/responsive.scss";

.about_us {
    $root: &;
    padding: 10px 20px;
    background: #fff;
    font-size: 15px;
    text-align: justify;
    color: #333;
    * {
        font-size: 15px;
        color: #333;
        text-align: justify;
    }
    p,
    ul {
        margin-bottom: 10px;
    }
    ul {
        list-style: disc;
        padding-left: 20px;
    }
    h3 {
        font-weight: 600;
        margin-bottom: 5px;
        font-size: 16px;
        color: #555;
    }
    strong {
        font-weight: 600;
        color: var(--main);
    }
    span {
        color: var(--link);
        font-size: 14px;
        font-weight: 600;
        white-space: nowrap;
    }
}
