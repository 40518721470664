@import "/src/layouts/comMaster/responsive.scss";
.nodata {
    text-align: center;
    color: #00000040;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 300px;
    cursor: pointer;
    > span,
    > b {
        color: var(--default);
        font-weight: 400;
        font-size: 13px;
    }
    > b {
        margin-top: -20px;
        margin-bottom: 30px;
    }
    svg {
        height: 100px;
        margin: 0 auto 8px auto;
    }
    .empty-img-default-ellipse {
        fill: #f5f5f5;
        fill-opacity: 0.8;
    }
    .empty-img-default-path-1 {
        fill: #aeb8c2;
    }
    .empty-img-default-path-2 {
        fill: #f5f5f7;
    }
    .empty-img-default-path-3 {
        fill: #f5f5f7;
    }
    .empty-img-default-path-4,
    .empty-img-default-path-5 {
        fill: #dce0e6;
    }
    .empty-img-default-g {
        fill: #fff;
    }
    .empty-img-simple-ellipse {
        fill: #f5f5f5;
    }
    .empty-img-simple-g {
        stroke: #d9d9d9;
    }
    .empty-img-simple-path {
        fill: #fafafa;
    }
}
