@import "/src/layouts/comMaster/responsive.scss";

.auth {
    height: 100%;
    $root: &;
    &__form {
        background: var(--light);
        padding: 20px 15px;
        border-radius: 10px;
        overflow: hidden;
        margin-top: 15px;
        #{$root}__form-content {
            #{$root}__form-item {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                margin-bottom: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
                input {
                    background: var(--light);
                    border: 1px solid var(--block);
                    color: var(--default);
                    width: 100%;
                    flex: 0 0 100%;
                    padding: 10px;
                }

                button {
                    width: 100%;
                    align-items: center;
                    border-radius: 50px;
                    background: var(--main-hover);
                    padding: 12px 16px;
                    text-transform: uppercase;
                    &:hover {
                        background: var(--main);
                    }
                    img {
                        width: auto;
                        height: 21px;
                    }
                    span {
                        color: var(--light);
                    }
                }
                #{$root}__form-icon {
                    position: relative;
                    width: 100%;
                    flex: 0 0 100%;
                    max-width: 100%;
                    i {
                        position: absolute;
                        top: 0;
                        right: 0;
                        color: var(--note);
                        width: 32px;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        opacity: 0.6;
                    }
                }
            }
        }
        #{$root}__form-footer {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px 0;
            a {
                color: var(--main);
                font-weight: 500;
            }
            span {
                color: var(--default);
                margin-right: 10px;
            }
        }
    }
}
