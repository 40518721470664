@import "/src/layouts/comMaster/responsive.scss";
$progress-color: #c2cfdb;

#nprogress .bar {
    background: $progress-color;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
}

#nprogress .peg {
    box-shadow: 0 0 10px $progress-color, 0 0 5px $progress-color;
}

#nprogress .spinner-icon {
    border-top-color: $progress-color;
    border-left-color: $progress-color;
    display: none;
}
.swal2-icon {
    transform: scale(0.6);
    margin-top: -15px !important;
    margin-bottom: -8px !important;
}
.swal2-html-container {
    margin-top: 0 !important;
    margin-bottom: 12px !important;
}
.swal2-styled.swal2-cancel {
    background: #a3a3a3 !important;
}
.swal2-title {
    padding: 0 !important;
    font-size: 15px !important;
    margin-bottom: 12px !important;
    font-weight: 400 !important;
    color: #000;
}
.swal2-popup {
    border-radius: 10px !important;
    width: auto;
    min-width: 350px;
    max-width: 100%;
    padding: 30px 20px 20px 20px !important;
    @include xs() {
        min-width: initial !important;
        width: 100% !important;
        max-width: 100% !important;
    }
}
.swal2-actions {
    margin-top: 0 !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    button {
        min-width: 80px;
        padding: 8px 12px !important;
        font-weight: 400;
        max-width: 100%;
        margin: 0 5px !important;
        font-size: 14px !important;
        word-spacing: normal;
        letter-spacing: normal;
        border-radius: 35px !important;
        &:focus {
            box-shadow: none !important;
        }
    }
}
.swal2-styled {
    padding: 8px 14px !important;
}
.Toastify__toast {
    height: fit-content;
    min-height: 38px;
    border-radius: 5px;
    margin-bottom: 6px;
    background: #fff;
    padding: 3px 0px 5px 5px;
    @include xs {
        padding: 5px 0px 8px 8px;
    }
}
.Toastify__toast-body {
    margin: 0;
    * {
        font-size: 13px;
        font-weight: 400;
    }
}
.Toastify__toast-container {
    top: 10px;
    width: fit-content;
    width: 300px;
    max-width: 350px;
    @include xs {
        width: calc(100vw - 20px);
        max-width: calc(100vw - 20px);
        top: 10px;
        right: 10px;
        left: auto;
    }
}
.Toastify--animate {
    animation-duration: 0.3s;
}
.Toastify__progress-bar {
    height: 2px;
    opacity: 0.6 !important;
}
.Toastify__progress-bar-theme--light:not(.Toastify__progress-bar--warning):not(
        .Toastify__progress-bar--success
    ):not(.Toastify__progress-bar--error):not(.Toastify__progress-bar--info) {
    background: #333333;
}
.Toastify__close-button {
    flex: 0 0 25px;
    width: 25px;
    height: 100%;
    align-self: center;
}
