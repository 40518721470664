@import "/src/layouts/comMaster/responsive.scss";
.notify {
    flex: 0 0 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 6px 42px 6px 12px;
    border-radius: 10px;
    font-size: 13px;
    line-height: 1.5;
    transition: var(--transition);
    box-shadow: 0px 0px 3px transparent;
    background: var(--block);
    margin-bottom: 10px;
    position: relative;
    > i {
        font-size: 14px;
        line-height: 1.5;
        height: 30px;
        width: 30px;
        position: absolute;
        top: 0;
        right: 0;
        color: var(--light);
        display: inline-flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    &.notify__small {
        padding: 4px 8px;
        font-size: 11px;
        i {
            font-size: 11px;
        }
    }
    &.notify__big {
        padding: 8px 14px;
        font-size: 14px;
        i {
            font-size: 14px;
        }
    }
    &.notify__primary {
        background: var(--primary);
        color: var(--light);
    }
    &.notify__warning {
        background: var(--warning);
        color: var(--light);
    }
    &.notify__success {
        background: var(--success);
        color: var(--light);
    }
    &.notify__info {
        background: var(--info);
        color: var(--light);
    }
    &.notify__default {
        background: var(--block);
        color: var(--default);
    }
    &.notify__danger {
        background: var(--danger);
        color: var(--light);
    }
    &.notify__dark {
        background: var(--dark);
        color: var(--light);
    }
}
