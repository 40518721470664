@import "/src/layouts/comMaster/responsive.scss";

.tab {
    $root: &;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--light);
    box-shadow: 0px 0px 5px #d5d5d5;
    color: #9b9b9b;
    * {
        color: #9b9b9b;
    }
    &__scroll-top {
        position: absolute;
        bottom: calc(100% + 30px);
        right: 10px;
        background: var(--main-hover);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 35px;
        width: 35px;
        opacity: 0;
        border-radius: 100%;
        cursor: pointer;
        i {
            font-size: 18px;
            color: var(--light);
        }
        &-active {
            opacity: 1;
        }
        &:hover {
            background: var(--main);
        }
    }
    &__container {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        #{$root}__item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 15px 0;
            position: relative;
            cursor: pointer;
            i {
                font-size: 20px;
            }
            span {
                font-size: 11px;
                font-weight: 400;
                margin-top: 3px;
                text-transform: uppercase;
            }
            &:hover,
            &.active {
                * {
                    color: var(--main);
                }
            }
        }
    }
}
