@import "/src/layouts/comMaster/responsive.scss";

.withdraw-history {
    $root: &;
    &__list {
        padding: 0 10px;
        #{$root}__item {
            margin: 10px 0;
            padding: 10px;
            background: var(--light);
            box-shadow: 0px 0px 5px var(--block);
            border-radius: 10px;

            #{$root}__item-head {
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-height: 26px;
                padding: 6px 0;
                border-bottom: 1px dashed #b5b5b5;
                margin-bottom: 5px;
            }
            #{$root}__item-row {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                min-height: 22px;
                > * {
                    color: var(--default);
                }
            }
            span {
                color: var(--default);
                font-weight: 400;
                font-size: 13px;
            }
            label {
                margin: 0;
            }
        }
    }
}
