$xxl: 1400px;
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 480px;
$xxs: 320px;

@mixin xxl {
    @media (max-width: #{$xxl}) {
        @content;
    }
}
@mixin xl {
    @media (max-width: #{$xl}) {
        @content;
    }
}
@mixin lg {
    @media (max-width: #{$lg}) {
        @content;
    }
}
@mixin md {
    @media (max-width: #{$md}) {
        @content;
    }
}
@mixin sm {
    @media (max-width: #{$sm}) {
        @content;
    }
}
@mixin xs {
    @media (max-width: #{$xs}) {
        @content;
    }
}
@mixin xxs {
    @media (max-width: #{$xxs}) {
        @content;
    }
}
