@import "/src/layouts/comMaster/responsive.scss";

.transaction {
    $root: &;
    &__list {
        padding: 10px;
        #{$root}__item {
            margin: 10px 0;
            padding: 10px;
            background: var(--light);
            box-shadow: 0px 0px 5px var(--block);
            border-radius: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
                color: var(--default);
                font-weight: 400;
                font-size: 13px;
            }
            i {
                flex: 0 0 35px;
                width: 35px;
                height: 35px;
                display: flex;
                margin-left: 10px;
                justify-content: center;
                align-items: center;
                color: #b3b3b3;
                font-size: 20px;
                cursor: pointer;
            }
        }
    }
}
