@import "/src/layouts/comMaster/responsive.scss";
.logo {
    width: 100%;
    height: 135px;
    display: flex;
    justify-content: center;
    align-items: center;
    > a {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        img {
            // filter: invert(1);
        }
    }
}
