@import "/src/layouts/comMaster/responsive.scss";
@import "normalize.css";
@import "layout.scss";
@import "keyframes.scss";
:root {
    --main: #b11c00;
    --main-hover: #ad1b00;
    --text: #333333;
    --light: #ffffff;
    --link: #6422c3;
    --link-hover: #0d6efd;
    --hover: #ffd972;
    --bg: linear-gradient(45deg, #ffd972, #f3bb3d, #ffe49c, #ffce4b);
    --bg-main: linear-gradient(180deg, #b11c00, #781503, #1c0400);
    --block: #ebebeb;
    --line: #c2cfdb;
    --note: #888c91;
    --primary: #2248c3;
    --warning: #ff820e;
    --success: #00b173;
    --info: #4d166e;
    --default: #444756;
    --danger: #d30e0e;
    --dark: #212529;
    --bom-bg: #232d3a;
    --bom-red: #f6465d;
    --bom-green: #2ebd85;
    --bom-purple: #b12ebd;
    --transition: all 100ms ease-in-out;
    --shadow: 5px 7px 26px -5px #cdd4e7;
}
.color__main {
    color: var(--main) !important;
}
.color__main-hover {
    color: var(--main-hover) !important;
}
.color__text {
    color: var(--text) !important;
}
.color__light {
    color: var(--light) !important;
}
.color__link {
    color: var(--link) !important;
}
.color__link-hover {
    color: var(--link-hover) !important;
}
.color__hover {
    color: var(--hover) !important;
}
.color__block {
    color: var(--block) !important;
}
.color__line {
    color: var(--line) !important;
}
.color__note {
    color: var(--note) !important;
}
.color__primary {
    color: var(--primary) !important;
}
.color__warning {
    color: var(--warning) !important;
}
.color__success {
    color: var(--success) !important;
}
.color__info {
    color: var(--info) !important;
}
.color__default {
    color: var(--default) !important;
}
.color__danger {
    color: var(--danger) !important;
}
.color__dark {
    color: var(--dark) !important;
}
.color__bom-bg {
    color: var(--bom-bg) !important;
}
.color__bom-red {
    color: var(--bom-red) !important;
}
.color__bom-green {
    color: var(--bom-green) !important;
}
.color__bom-purple {
    color: var(--bom-purple) !important;
}
.bg__main {
    background: var(--main) !important;
}
.bg__main-hover {
    background: var(--main-hover) !important;
}
.bg__text {
    background: var(--text) !important;
}
.bg__light {
    background: var(--light) !important;
}
.bg__link {
    background: var(--link) !important;
}
.bg__link-hover {
    background: var(--link-hover) !important;
}
.bg__hover {
    background: var(--hover) !important;
}
.bg__block {
    background: var(--block) !important;
}
.bg__line {
    background: var(--line) !important;
}
.bg__note {
    background: var(--note) !important;
}
.bg__primary {
    background: var(--primary) !important;
}
.bg__warning {
    background: var(--warning) !important;
}
.bg__success {
    background: var(--success) !important;
}
.bg__info {
    background: var(--info) !important;
}
.bg__default {
    background: var(--default) !important;
}
.bg__danger {
    background: var(--danger) !important;
}
.bg__dark {
    background: var(--dark) !important;
}
.bg__bom-bg {
    background: var(--bom-bg) !important;
}
.bg__bom-red {
    background: var(--bom-red) !important;
}
.bg__bom-green {
    background: var(--bom-green) !important;
}
.bg__bom-purple {
    background: var(--bom-purple) !important;
}
.bg__bom-red-purple {
    background: linear-gradient(
        309deg,
        var(--bom-purple),
        var(--bom-purple),
        var(--bom-red),
        var(--bom-red)
    ) !important;
}
.bg__bom-green-purple {
    background: linear-gradient(
        309deg,
        var(--bom-purple),
        var(--bom-purple),
        var(--bom-green),
        var(--bom-green)
    ) !important;
}
.fw__normal {
    font-weight: 400 !important;
}
.fw__bold {
    font-weight: 500 !important;
}
.fw__bolder {
    font-weight: 600 !important;
}
.text__center {
    text-align: center !important;
}
.text__left {
    text-align: left !important;
}
.text__right {
    text-align: right !important;
}
* {
    font-size: 13px;
    font-weight: 400;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    color: var(--text);
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif, system-ui,
        -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans,
        Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    line-height: 1.5;
    word-break: break-word;
    transition: var(--transition);
}

*:focus,
*:focus-visible,
*:focus-within {
    outline: none;
}

a {
    text-decoration: none;
    font-size: 13px;
    &:hover,
    &:hover > * {
        color: var(--hover);
    }
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}

main {
    display: block;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

pre {
    font-family: monospace, monospace;
    font-size: 1em;
}

a {
    background-color: transparent;
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
}

b {
    font-weight: bold;
}
strong {
    font-weight: 500;
}

code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

img {
    border-style: none;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

[type="button"],
[type="reset"],
[type="submit"],
button {
    -webkit-appearance: button;
}

[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring,
button:-moz-focusring {
    outline: 1px dotted ButtonText;
}

fieldset {
    padding: 0.35em 0.75em 0.625em;
}

legend {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

textarea {
    overflow: auto;
}

[type="checkbox"],
[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

details {
    display: block;
}

summary {
    display: list-item;
}

template {
    display: none;
}

[hidden] {
    display: none;
}
.wrap__image {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    height: fit-content;
}
img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
}
body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

body {
    scrollbar-width: thin;
    scrollbar-color: #6c757d var(--block);
}

body::-webkit-scrollbar-track {
    background: var(--block);
}

body::-webkit-scrollbar-thumb {
    background-color: #6c757d;
    border: 1px solid var(--block);
}
*::placeholder {
    color: #6c757d;
    opacity: 1;
}
select,
button,
input,
textarea:focus,
textarea:focus,
.btn-close:focus,
input:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"]:focus,
[type="password"]:focus,
[type="email"]:focus,
[type="tel"]:focus,
[contenteditable]:focus {
    box-shadow: none;
    outline: none;
}
input,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
textarea,
select {
    padding: 6px 8px;
    font-size: 13px;
    color: #212529;
    line-height: 20px;
    text-decoration: none;
    vertical-align: middle;
    border: 1px solid var(--line);
    cursor: pointer;
    width: 100%;
}

input[name="daterange"] {
    cursor: pointer;
}
input[readonly],
input[disabled] {
    color: var(--note) !important;
    background: #eaeef1;
}
table th,
table th * {
    color: #234983;
}
th {
    font-weight: 500;
    opacity: 1;
}
.slick-slider {
    display: flex;
    width: 100%;
    height: 100%;
    .slick-list {
        display: flex;
        height: 100%;
        width: 100%;
        .slick-track {
            display: flex;
            height: 100%;
            .slick-slide {
                height: 100%;
                display: flex;
                > div {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    img {
                        width: 100%;
                        height: 100%;
                        display: block !important;
                    }
                }
            }
        }
    }
    .slick-prev,
    .slick-next {
        color: var(--default);
    }
    .slick-prev {
        &:before {
            content: "\f100";
            font-family: "Font Awesome 6 Pro";
            color: var(--default);
        }
    }
    .slick-next {
        &:before {
            content: "\f101";
            font-family: "Font Awesome 6 Pro";
            color: var(--default);
        }
    }
}
