@import "/src/layouts/comMaster/responsive.scss";

.account {
    $root: &;
    &__user {
        background: var(--bg-main);
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        padding-bottom: 50px;
        #{$root}__user-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            #{$root}__user-info {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-radius: 55px;
                background: var(--bg-main);
                > img {
                    width: 55px;
                    height: 55px;
                    object-fit: cover;
                    border-radius: 100%;
                    margin-right: 10px;
                    border: 2px solid var(--hover);
                }
                > div {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding-right: 15px;
                    > strong {
                        font-size: 16px;
                        font-weight: 400;
                        color: var(--light);
                        line-height: 25px;
                        cursor: pointer;
                        > i:first-child {
                            margin-right: 8px;
                            color: var(--light);
                        }
                        > i:last-child {
                            margin-left: 12px;
                            color: var(--block);
                            font-size: 18px;
                        }
                    }
                    > span {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        max-width: 150px;
                        white-space: nowrap;
                        -o-text-overflow: ellipsis;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        color: var(--block);
                    }
                }
            }
            #{$root}__user-vip {
                margin: 15px 0;
            }
            #{$root}__user-code {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 15px 0;
                color: var(--block);
                font-size: 14px;
                cursor: pointer;
                > div {
                    > * {
                        color: var(--block);
                    }
                    > span {
                        font-size: 14px;
                        margin-right: 6px;
                        margin-left: 16px;
                        color: var(--hover);
                    }
                    > i {
                        color: var(--block);
                        font-size: 18px;
                    }
                }
            }
        }
    }
    &__money {
        background: var(--light);
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: -45px;
        padding: 10px 15px;
        border-radius: 15px;
        #{$root}__money-balance {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            > strong {
                font-weight: 500;
                font-size: 15px;
                color: var(--default);
            }
            > span {
                font-size: 20px;
                font-weight: bold;
                color: var(--main);
            }
        }
        #{$root}__money-link {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-top: 10px;
            > span {
                width: 1px;
                background: #b9b9b9;
                height: 30px;
            }
            #{$root}__money-item {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                i {
                    font-size: 25px;
                    color: #555;
                    margin-bottom: 5px;
                }
                span {
                    color: #555;
                    font-weight: bold;
                    font-size: 12px;
                }
                &:hover {
                    i,
                    span {
                        color: var(--main);
                    }
                }
            }
        }
    }
    &__menu {
        background: var(--light);
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        padding: 10px 15px;
        border-radius: 15px;
        #{$root}__menu-item {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid #ededed;
            padding: 5px 0;
            > i:first-child {
                font-size: 20px;
                height: 35px;
                width: 35px;
                flex: 0 0 35px;
                line-height: 35px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
                color: var(--default);
            }
            > a {
                width: calc(100% - 35px - 35px - 10px - 10px);
                flex: 0 0 calc(100% - 35px - 35px - 10px - 10px);
                font-size: 15px;
                font-weight: 500;
                line-height: 35px;
                height: 35px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: var(--default);
                &:hover {
                    color: var(--main);
                }
            }
            > i:last-child {
                margin-left: auto;
                height: 35px;
                width: 35px;
                flex: 0 0 35px;
                line-height: 35px;
                display: flex;
                font-size: 10px;
                justify-content: center;
                align-items: center;
                color: #b9b9b9;
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }
    &__logout {
        background: var(--main-hover);
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
        padding: 10px 15px;
        border-radius: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--light);
        font-weight: 400;
        font-size: 14px;
        text-transform: uppercase;
        cursor: pointer;
        margin-bottom: 30px;
        &:hover {
            background: var(--main);
        }
    }
}
