@import "/src/layouts/comMaster/responsive.scss";

@keyframes textRunning {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}
@keyframes spinBallActive {
    from {
        background: linear-gradient(0deg, #870101, #d30e0e, #870101);
    }
    to {
        background: linear-gradient(270deg, #870101, #d30e0e, #870101);
    }
}
@keyframes zoomBall {
    0% {
        transform: scale(1.3);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes shakeBall {
    0% {
        transform: translateY(-10px);
    }
    10% {
        transform: translateY(0px);
    }
    20% {
        transform: translateY(-2px);
    }
    40% {
        transform: translateY(2px);
    }
    60% {
        transform: translateY(-2px);
    }
    80% {
        transform: translateY(2px);
    }
    100% {
        transform: translateY(0);
    }
}
